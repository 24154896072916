<template>
  <div class="container">
    <div class="about-container">
      <div class="markdown">
        <vue3-markdown-it
          v-for="contentBlock in pageContents"
          :source="contentBlock.text"
          :key="contentBlock.id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getPageContent } from "@/services/PageService.js";
export default {
  name: "About",
  data() {
    return {
      pageContents: [
        // {
        //   order: Number,
        //   id: Number,
        //   text: String,
        //   image: String,
        // },
      ],
    };
  },
  computed: {},
  methods: {
    fetchPageContents() {
      getPageContent("About")
        .then((theData) => {
          this.pageContents = theData;
        })
        .catch((err) => {
          console.log(
            "Oh noes, something went wrong getting page contents: ",
            err
          );
        });
    },
  },
  mounted() {
    this.fetchPageContents();
  },
};
</script>
<style scoped>
.about-container {
  max-width: 60rem;
  margin: 0 auto;
  padding-top: 2rem;
}
</style>
